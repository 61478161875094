<template>
  <div>
    <validation-observer ref="simpleRules">
      <!-- <b-card>
        <h3 class="text-center">
          QUESTIONNAIRE DE RENSEIGNEMENTS A L'AEROPORT DE COTONOU
        </h3>
      </b-card> -->

      <b-card>
        <!-- head -->
        <b-card-header>
          <b-row no-gutters class="w-100">
            <b-col md="12">
              <div class="d-flex justify-content-end">
                <div>
                  <b-button
                    size="lg"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon rounded-circle"
                    title="Informations"
                    v-b-popover.hover.focus="
                      '⚪️ Le questionnaire de renseignements d\'arrivée à l\'aéroport de Cotonou est éditable par les voyageurs partant du Bénin par l\'aéroport et agents annexes. \
                    ⚪️ Il permet de collecter les données sur les voyageurs et leur séjours. ⚪️ Le remplissage de la fiche ne nécessite pas une connexion ou la création d\'un compte.'
                    "
                  >
                    <feather-icon icon="BellIcon" />
                  </b-button>
                </div>
                <!-- <div >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon rounded-circle"
                    v-b-modal.modal-info
                  >
                    <feather-icon icon="BellIcon" />
                  </b-button>
                </div> -->
              </div>
            </b-col>
            <b-col md="2">
              <b-card-img
                :src="
                  require('@/assets/images/illustration/Globalization-pana.png')
                "
                alt="Image"
                class="rounded-0"
              >
              </b-card-img>
            </b-col>
            <b-col
              md="10"
              class="d-flex align-items-center justify-content-center"
            >
              <div>
                <!-- <h1 class="m-auto text-center">FICHE STATISTIQUE</h1> -->
                <br />
                <h4 class="m-auto text-center">
                  QUESTIONNAIRE DE RENSEIGNEMENTS A L'AÉROPORT DE COTONOU
                  (ENTREE)
                </h4>
              </div>
            </b-col>
          </b-row>
        </b-card-header>

        <b-card-body>
          <div>
            <!-- <b-row > -->

            <!-- Date d'arrivée au Bénin  -->
            <b-row>
              <b-col md="12" class="mt-2">
                <validation-provider
                  #default="{ errors }"
                  name="Date"
                  rules="required"
                >
                  <b-form-group
                    label-cols-lg="2"
                    label="Date d'arrivée au Bénin"
                    label-for="date"
                  >
                    <flat-pickr
                      v-model="sendForm.dateArrivee"
                      class="form-control"
                      placeholder="Date"
                      value="date"
                      type="date"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- Compagnie Aérienne -->
            <b-row>
              <b-col md="12" class="mt-2">
                <!-- Durée de sejour  -->
                <b-form-group label-cols-lg="2" label="Compagnie aérienne">
                  <validation-provider
                    #default="{ errors }"
                    name="Compagnie Aérienne"
                    rules="required"
                  >
                    <b-form-input
                      v-model.number="sendForm.compagnieAerienne"
                      placeholder="Air France"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Pays de résidence  -->
            <b-row>
              <b-col md="12" class="mt-2">
                <validation-provider
                  #default="{ errors }"
                  name="Pays de Résidence"
                  rules="required"
                >
                  <b-form-group
                    label-cols-lg="2"
                    label="Pays de résidence"
                    label-for="pays_residence"
                  >
                    <v-select
                      v-model="sendForm.paysResidence"
                      type="text"
                      :options="pays_residence"
                      label="title"
                      placeholder="Pays de résidence"
                      size="sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Nationalité -->
            <span v-if="sendForm.paysResidence != 'Bénin'">
              <b-row>
                <b-col md="12" class="mt-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Nationalité"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-lg="2"
                      label="Nationalité"
                      label-for="nationality"
                    >
                      <v-select
                        v-model="sendForm.nationalite"
                        type="text"
                        :options="pays_residence"
                        label="title"
                        placeholder="Nationalité"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- Durée de sejour  -->
              <b-row>
                <b-col md="12" class="mt-2">
                  <b-form-group
                    label-cols-lg="2"
                    label="Durée du séjour (Mois - Jours)"
                    label-for="duree"
                  >
                    <div class="d-flex">
                      <div class="d-inline-block w-50">
                        <b-form-input
                          v-model.number="dureeSejourMois"
                          placeholder="Nombre de mois"
                          type="number"
                        />
                      </div>
                      <div class="d-inline-block w-50">
                        <validation-provider
                          #default="{ errors }"
                          name="Durée"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="dureeSejourJours"
                            placeholder="Nombre de jours"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Motif principal  -->
              <b-row>
                <b-col md="12" class="mt-2">
                  <b-form-group
                    label-cols-lg="2"
                    label-size="lg"
                    label="Motif Principal de Voyage"
                    label-for="motif_travel"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Motif Principal de Voyage"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="sendForm.motifVoyage"
                        :options="motif_travel"
                        value-field="id"
                        text-field="name"
                        name="some-radio9"
                        stacked
                      />
                      <!-- class="demo-inline-spacing control-label col-sm-3" -->
                      <b-form-input
                        v-if="sendForm.motif_travel == 'Autres (A preciser)'"
                        v-model="sendForm.motifVoyage"
                        placeholder="Autres"
                        class="mt-2"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Mode d'hebergement  -->
              <b-row>
                <b-col md="12" class="mt-2">
                  <b-form-group
                    label-cols-lg="2"
                    label="Mode d'hébergement"
                    label-for="hebergment"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Hebergement"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="sendForm.modeHebergement"
                        value-field="id"
                        text-field="name"
                        :options="hebergement"
                        class="demo-inline-spacing"
                        name="radio-validation"
                      />
                      <b-form-input
                        v-if="sendForm.modeHebergement == 'Autres (A preciser)'"
                        v-model="autresModeHebergement"
                        placeholder="Autres mode d'hébergement"
                        class="mt-2"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Commune  -->
              <b-row>
                <b-col md="12" class="mt-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Commune"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-lg="2"
                      label="Commune / Ville"
                      label-for="commune"
                    >
                      <v-select
                        v-model="sendForm.villeCommune"
                        type="text"
                        :options="commune"
                        placeholder="Commune/Ville"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- Rémuneration -->
              <b-row>
                <b-col md="12" class="mt-2">
                  <b-form-group
                    label-cols-lg="2"
                    label="Allez-vous recevoir une rémunération d'une entité du pays / lieu visité durant votre séjour ?"
                    label-for="remuneration"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Remuneration"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="sendForm.possibiliteRemuneration"
                        :options="remuneration"
                        class="demo-inline-spacing"
                        name="radio-inline"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Age  -->
              <b-row>
                <b-col md="12" class="mt-2">
                  <b-form-group label-cols-lg="2" label="Age" label-for="age">
                    <validation-provider
                      #default="{ errors }"
                      name="Age"
                      rules="required|integer"
                    >
                      <b-form-input
                        v-model.number="sendForm.age"
                        placeholder="Age"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Sexe  -->
              <b-row>
                <b-col md="12" class="mt-2">
                  <b-form-group label-cols-lg="2" label="Sexe" label-for="sexe">
                    <validation-provider
                      #default="{ errors }"
                      name="Sexe"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="sendForm.sexe"
                        :options="sexe"
                        class="demo-inline-spacing"
                        name="radio-sexe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Nom & Prénom  -->
              <b-row>
                <b-col md="12" class="mt-2">
                  <b-form-group
                    label-cols-lg="2"
                    label="Nom et Prénom"
                    label-for="nom_prenom"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nom & Prenom"
                      rules="required"
                    >
                      <b-form-input
                        v-model="sendForm.fullname"
                        placeholder="Nom et Prénom"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </span>
            <!-- Nom & Prénom enqueteur  -->
            <b-row>
              <b-col md="12" class="mt-2">
                <validation-provider
                  #default="{ errors }"
                  name="Nom_Enqueteur"
                  rules="required"
                >
                  <b-form-group
                    label-cols-lg="2"
                    label="Nom de l'enquêteur"
                    label-for="enqueteur"
                  >
                    <b-form-input
                      v-model.number="sendForm.enqueteur"
                      placeholder="Nom de l'enquêteur"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>
        </b-card-body>

        <!-- Signature -->
        <!-- <b-form-group class="mt-4">
          <h4 class="text-center">
            Signature
          </h4>
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <b-form-file
              v-model="sendForm.signature"
              class="mb-2"
              placeholder="Choisissez signature ou glisser et déposer le ici ..."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->
      </b-card>

      <!-- Button d'enregistrement -->
      <div class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="col-xl-2"
          @click="sendInfosEntreeAeroport()"
        >
          Aperçu avant enregistrement
        </b-button>
      </div>
    </validation-observer>

    <!-- modals -->
    <b-modal id="modal-info" title="Informations" ok-only ok-title="Fermer">
      <b-card-text>
        <h5>Aux voyageurs arrivant au Bénin par l'aéroport</h5>
        ⚪️ Le questionnaire de renseignements de départ à l'aéroport de Cotonou
        est éditable par les voyageurs arrivant au Bénin par l'aéroport et les
        annexes. <br />
        ⚪️ Elle permet de collecter les données sur les voyageurs et leur
        séjours. <br />
        ⚪️ Le remplissage de la fiche ne nécessite pas une connexion ou la
        création d'un compte.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCardImg,
  BCol,
  BRow,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BFormFile,
  BModal,
  VBModal,
  BAlert,
  VBPopover,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
// import axios from 'axios'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import utilsService from "@/services/utils/utils.service";
import moment from "moment";

// import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCardImg,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormFile,
    BModal,
    VBModal,
    BAlert,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    "b-popover": VBPopover,
  },

  data() {
    return {
      required,
      // Pays de residence
      pays_residence: [],
      // Nationality
      nationality: [
        { title: "Benin" },
        { title: "Togolaise" },
        { title: "Nigeria" },
      ],
      // Select Motif_Travel: '',
      motif_travel: [
        {
          id: "Affaires et motif professionnels",
          name: "Affaires et motif professionnels",
        },
        {
          id: "Vacances, loisirs et détente",
          name: "Vacances, loisirs et détente",
        },
        {
          id: "Visite à des parents ou des amis",
          name: "Visite à des parents ou des amis",
        },
        { id: "Éducation et formation", name: "Éducation et formation" },
        { id: "Santé et soins médicaux", name: "Santé et soins médicaux" },
        { id: "Transit", name: "Transit" },
        { id: "Religion ou pèlerinage", name: "Religion ou pèlerinage" },
        { id: "Achats", name: "Achats" },
        { id: "Autres (A preciser)", name: "Autres motif (A preciser)" },
      ],
      // Radio Hebergement: '',
      hebergement: [
        { id: "Hotel et assimilé", name: "Hotel et assimilé" },
        { id: "Familles/Amis", name: "Familles/Amis" },
        // { id: 'Visite à des parents ou des amis', name: 'Visite à des parents ou des amis' },
        { id: "Autres (A preciser)", name: "Autres (A preciser)" },
      ],
      // Autre motif de voyage
      // autres_motif: [],
      // Autre hebergemnt
      // autres_hebergement: [],
      // Radio Remuneration: '',
      remuneration: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      // Select commune
      commune: [],
      // Select Enqueteur
      // enqueteur: [
      //   { title: 'Enqueteur1' },
      //   { title: 'Enqueteur2' },
      //   { title: 'Enqueteur3' },
      //   { title: 'Enqueteur4' },
      // ],
      // Radio sexe
      sexe: [],
      sendForm: {
        dateArrivee: null,
        compagnieAerienne: null,
        paysResidence: null,
        nationalite: null,
        dureeSejour: null,
        motifVoyage: null,
        modeHebergement: null,
        possibiliteRemuneration: null,
        villeCommune: null,
        age: null,
        sexe: null,
        fullname: null,
        enqueteur: null,
      },
      autresModeHebergement: null,
      dureeSejourMois: null,
      dureeSejourJours: null,
    };
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        const { countries, sexs, communes } = utilsService.currentUserUtils();
        // // console.log('countries: ', countries);
        this.pays_residence = countries;
        this.nationality = countries;
        this.sexe = sexs;
        this.commune = communes;
      },
    },
  },

  methods: {
    // ...mapActions('etablissements', { action_findEtablissement: 'findEtablissement' }),
    // getListEtablissement() {
    //   this.action_findEtablissement()
    //     .then(response => {
    //       this.lites_hotels = response.data.resources
    //       // console.log('list-etablissement', response, this.lites_hotels)
    //     })
    //     .catch(err => {
    //       // this.isLoadingTableData = false
    //       // console.log('error-list-etablissement', err)
    //     })
    // },
    sendInfosEntreeAeroport() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // console.log(this.dureeSejourMois, this.dureeSejourJours)
          if (this.sendForm.dateArrivee) {
            let futurDate = null;
            let currentDate = moment(
              this.sendForm.dateArrivee.split("-").map((e) => parseInt(e))
            );
            if (this.dureeSejourMois > 0) {
              futurDate = moment(
                this.sendForm.dateArrivee.split("-").map((e) => parseInt(e))
              ).add(this.dureeSejourMois - 1, "month");
            } else {
              futurDate = currentDate;
            }
            if (this.dureeSejourJours > 0) {
              futurDate.add(this.dureeSejourJours, "days");
            }
            const diffNbreJours = futurDate.diff(currentDate, "days");
            this.sendForm.dureeSejour = diffNbreJours;
          }

          if (this.autresModeHebergement)
            this.sendForm.modeHebergement = this.autresModeHebergement;
          if (this.sendForm.nationalite) {
            if (this.sendForm.nationalite.title)
              this.sendForm.nationalite = this.sendForm.nationalite.title;
          }

          // // console.log('this.sendForm: ', this.sendForm)
          // const form = this.sendForm
          this.$emit("onFormSucess", {
            routerParams: { preview: true, data: this.sendForm },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Champs requis",
              icon: "TriangleIcon",
              // text: '👋 Chocolate oat cake jelly oat cake candy jelly beans pastry.',
              variant: "danger",
            },
          });
        }
      });

      // this.sendForm = {
      //   npi: null,
      //   dateArrivee: null,
      //   heureArrivee: null,
      //   numeroChambre: null,
      //   dateDepart: null,
      //   lastname: null,
      //   firstname: null,
      //   maidenname: null,
      //   sexe: null,
      //   dateNaissance: null,
      //   lieuNaissance: null,
      //   nationalite: null,
      //   adresse: null,
      //   bp: null,
      //   villeCommune: null,
      //   departement: null,
      //   pays: null,
      //   telephone: null,
      //   email: null,
      //   categorie: null,
      //   autrescategorie: null,
      //   modePrincipalTransport: null,
      //   motifPrincipaleVoyage: null,
      //   autresMotifPrincipalVoyage: null,
      //   question: null,
      //   peiceIdentite: null,
      //   numeroPiece: null,
      //   delivrePar: null,
      //   a: null,
      //   signature: null,
      // }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
